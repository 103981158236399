import React from 'react';
import cn from 'classnames';
import { Grid, SVG } from '@palomina/palomina-common/components';
import { SliceConfig } from '@palomina/palomina-website/components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { IPalominaLogoBanner } from '@palomina/palomina-sanity';

import * as styles from './styles.module.scss';

interface IProps {
  data: IPalominaLogoBanner;
}

const LogoBanner = ({ data: { media, sliceConfig } }: IProps) => {
  return (
    <SliceConfig config={sliceConfig}>
      <div className={styles.container}>
        <figure className={styles.media}>
          {(media?.video?.url && (
            <video autoPlay muted loop playsInline>
              <source src={media.video.url} type="video/mp4" />
            </video>
          )) || (
            <>
              {media?.image?.asset && (
                <GatsbyImage
                  style={{ padding: `0` }}
                  image={media.image.asset.gatsbyImageData}
                  alt={media.image.altText || 'Palomina'}
                />
              )}
            </>
          )}
        </figure>
        <Grid className={styles.grid}>
          <figure className={cn(styles.wordmark, { [styles.large]: !media })}>
            <SVG svg="palopayWordmark" />
          </figure>
        </Grid>
      </div>
    </SliceConfig>
  );
};

export default LogoBanner;
