import React, { useEffect, useState } from 'react';
import { Button, Carousel, Grid } from '@palomina/palomina-common/components';
import { GatsbyImage } from 'gatsby-plugin-image';
import useEmblaCarousel from 'embla-carousel-react';
import { IPalominaMediaOnly } from '@palomina/palomina-sanity';
import { useBreakpoints } from '@palomina/palomina-common/hooks';

import * as styles from './styles.module.scss';

interface IProps {
  data: IPalominaMediaOnly;
}

const MediaOnly = ({ data: { mediaItems } }: IProps) => {
  if (!mediaItems?.[0]) return null;
  const { smallMobile, tablet, largeTablet } = useBreakpoints();
  const asCarousel = mediaItems.length > 1;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [spaceBetween, setSpaceBetween] = useState(0);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: `start`
  });

  const onCarouselPrevClick = () => {
    if (emblaApi) {
      emblaApi.scrollPrev();
    }
  };

  const onCarouselNextClick = () => {
    if (emblaApi) {
      emblaApi.scrollNext();
    }
  };

  useEffect(() => {
    let spacePx;
    if (smallMobile) {
      spacePx = 16;
    }

    if (tablet) {
      spacePx = 24;
    }

    if (largeTablet) {
      spacePx = 40;
    }

    setSpaceBetween(spacePx);
  }, [smallMobile, tablet, largeTablet]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', () => {
        setSelectedIndex(emblaApi.selectedScrollSnap());
      });
    }
  }, [emblaApi]);

  // useEffect(() => {
  //   console.log(selectedIndex, 'selectedIndex');
  // }, [selectedIndex]);

  return (
    <div className={styles.container}>
      {(asCarousel && (
        <Grid className={styles.carouselGrid}>
          <div className={styles.carouselContainer}>
            <Carousel
              embla={{
                api: emblaApi,
                ref: emblaRef
              }}
              className={styles.carousel}
              slidesPerView={1}
              spaceBetween={spaceBetween}
              slides={() =>
                mediaItems.map((item) => (
                  <figure key={item._key} className={styles.media}>
                    {(item.video?.url && (
                      <video autoPlay muted loop playsInline>
                        <source src={item.video.url} type="video/mp4" />
                      </video>
                    )) || (
                      <>
                        {item.image?.asset && (
                          <GatsbyImage
                            style={{ padding: `0` }}
                            image={item.image.asset.gatsbyImageData}
                            alt={item.image.altText || 'Palomina'}
                          />
                        )}
                      </>
                    )}
                  </figure>
                ))
              }
            />

            <div className={styles.carouselNav}>
              <Button
                onClick={() => onCarouselPrevClick()}
                variant="iconButton"
                icon="arrowLeft"
                text="Carousel Previous"
                disabled={selectedIndex === 0}
              />
              <Button
                onClick={() => onCarouselNextClick()}
                variant="iconButton"
                icon="arrowRight"
                text="Carousel Next"
                disabled={selectedIndex === mediaItems?.length - 1}
              />
            </div>
          </div>
        </Grid>
      )) || (
        <div className={styles.singleMedia}>
          <figure className={styles.media}>
            {(mediaItems[0].video?.url && (
              <video autoPlay muted loop playsInline>
                <source src={mediaItems[0].video.url} type="video/mp4" />
              </video>
            )) || (
              <>
                {mediaItems[0].image?.asset && (
                  <GatsbyImage
                    style={{ padding: `0` }}
                    image={mediaItems[0].image.asset.gatsbyImageData}
                    alt={mediaItems[0].image.altText || 'Palomina'}
                  />
                )}
              </>
            )}
          </figure>
        </div>
      )}
    </div>
  );
};

export default MediaOnly;
