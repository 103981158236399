import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Button, Grid, SVG } from '@palomina/palomina-common/components';
import { IPalopaySettings } from '@palomina/palomina-sanity';
import * as styles from './styles.module.scss';

const Header = () => {
  const siteSettings: IPalopaySettings = useStaticQuery(graphql`
    query {
      sanityPalopaySettings {
        contactEmail
      }
    }
  `)?.sanityPalopaySettings;

  const { contactEmail } = siteSettings || {};

  if (!contactEmail) return null;

  return (
    <header className={styles.container}>
      <Grid>
        <div className={styles.content}>
          <figure className={styles.logo}>{/* <SVG svg="logo" /> */}</figure>

          <Button
            className={styles.buttonMobile}
            text="Get in touch"
            href={`mailto:${contactEmail}`}
            variant="iconButton"
            icon="mail"
          />
          <Button
            className={styles.button}
            text="Get in touch"
            href={`mailto:${contactEmail}`}
            iconRight="arrowRight"
          />
        </div>
      </Grid>
    </header>
  );
};

export default Header;
