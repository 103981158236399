import React from 'react';
import {
  Theme,
  Menu,
  Header,
  Footer
} from '@palomina/palomina-website/components';
import { NoJs } from '@palomina/palomina-common/components';
import 'modern-normalize/modern-normalize.css';
import '@palomina/palomina-common/styles/global.css';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const Layout = ({ children }: IProps) => (
  <>
    <NoJs />
    <Theme />

    <Menu />

    <Header />

    {children}

    <Footer />
  </>
);

export default Layout;
