import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@palomina/palomina-common/components';
import cn from 'classnames';
import { useApp } from '@palomina/palomina-website/hooks';
import { Link } from 'gatsby';

import * as styles from './styles.module.scss';

const Menu = () => {
  const { isMenuOpen } = useApp();

  return (
    <div className={cn(styles.container, { [styles.active]: isMenuOpen })}>
      <Grid className={styles.content}>
        <ul className={styles.links}>
          <li className="d1">
            <Link to="/">Home</Link>
          </li>
          <li className="d1">
            <Link to="/campaigns">Campaigns</Link>
          </li>
          <li className="d1">
            <Link to="/news">News</Link>
          </li>
        </ul>
      </Grid>
    </div>
  );
};

export default Menu;
