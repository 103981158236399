import React from 'react';
import cn from 'classnames';
import { ISliceConfig } from '@palomina/palomina-sanity';
import * as styles from './styles.module.scss';

interface IProps {
  children: React.ReactNode;
  config?: ISliceConfig;
  noPadding?: boolean;
}

interface ISliceConfigColors {
  color?: string;
  backgroundColor?: string;
}

const SliceConfig = ({ children, config, noPadding }: IProps) => {
  const { backgroundColor, textColor, slicePadding } = config || {};
  const { paddingTop = `regular`, paddingBottom = `regular` } =
    slicePadding || {};

  const colorStyles: ISliceConfigColors = {};
  if (textColor?.hex) {
    colorStyles.color = textColor.hex;
  }
  if (backgroundColor?.hex) {
    colorStyles.backgroundColor = backgroundColor.hex;
  }

  return (
    <div
      style={colorStyles}
      className={cn(styles.container, {
        // [styles.paddingTopRegular]: !noPadding && paddingTop === `regular`,
        // [styles.paddingTopSmall]: !noPadding && paddingTop === `small`,
        // [styles.paddingBottomRegular]:
        //   !noPadding && paddingBottom === `regular`,
        // [styles.paddingBottomSmall]: !noPadding && paddingBottom === `small`
      })}
    >
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default SliceConfig;
